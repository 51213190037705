import { useParams } from '@solidjs/router';
import { Component, createSignal, For, onMount, Show } from 'solid-js';
import { ADD_RENTAL_HISTORY } from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { PresentableRentalHistory, RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { AddRentalHistoryUseCase } from '~/use-cases/rental-applications/application-steps/addRentalHistoryUseCase';
import { RemoveRentallHistoryErrorUseCase } from '~/use-cases/rental-applications/application-steps/removeRentalHistoryUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalHistoryUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalHistoryUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { GoToPrevStepUseCase } from '~/use-cases/rental-applications/goToPrevStepUseCase';
import { RentalHistoryItem } from './RentalHistoryItem';

export const RentalHistoryStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: goToPrevStep } = useUseCase(GoToPrevStepUseCase);
  const { execute: goToNextStep, didSucceed: didSucceedGoingNext } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalHistory } = useUseCase(ValidateRentalHistoryUseCase);
  const { execute: removeRentallHistoryError } = useUseCase(RemoveRentallHistoryErrorUseCase);
  const { execute: addNewRentalHistory } = useUseCase(AddRentalHistoryUseCase);

  onMount(() => {
    getRentalApplication({ id: params.id, password: params.password });
  });

  const onRemoveRentalHistory = (index: number) => {
    const history = presentableRentalApplication()?.rentalHistory?.filter((_, i) => i !== index);
    updateRentalApplication({
      history: history,
    });
    removeRentallHistoryError(index);
  };

  const onUpdateHistoryItem = (historyItem: PresentableRentalHistory, index: number) => {
    const history = (presentableRentalApplication()?.rentalHistory ?? []).map((item, i) => (i === index ? historyItem : item));
    updateRentalApplication({
      history: history,
    });
  };

  const onPrev = async () => {
    await goToPrevStep({
      currentStep: RentalApplicationSteps.RENTAL_HISTORY,
      credentials: { id: params.id, password: params.password },
    });
  };

  const onNext = async () => {
    if (!presentableRentalApplication()?.rentalHistory?.length) {
      return;
    }
    await validateRentalHistory();
    if (presentableRentalApplication()?.errors?.hasRentalHistoryError) {
      return;
    }
    setIsGoingToNextStep(true);
    await goToNextStep({
      currentStep: RentalApplicationSteps.RENTAL_HISTORY,
      credentials: { id: params.id, password: params.password },
    });
    if (!didSucceedGoingNext()) {
      setIsGoingToNextStep(false);
    }
  };

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3 flex-col overflow-y-auto bg-white p-5">
        <Show when={(presentableRentalApplication()?.rentalHistory?.length ?? 0) > 0}>
          <For each={presentableRentalApplication()?.rentalHistory}>
            {(history, index) => (
              <RentalHistoryItem
                history={history}
                index={index()}
                errors={presentableRentalApplication()?.rentalHistoryErrors?.[index()]}
                onUpdate={onUpdateHistoryItem}
                onDelete={onRemoveRentalHistory}
              />
            )}
          </For>
        </Show>
        <button class="mt-4 w-48 text-left text-primary-color hover:underline" type="button" onClick={addNewRentalHistory}>
          + {t(ADD_RENTAL_HISTORY)}
        </button>
      </div>
      <StepFooter onNext={onNext} onPrevious={onPrev} />
    </Show>
  );
};
