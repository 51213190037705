import { MagicUseCase } from '~/use-cases/magicUseCase';

export class RemoveRentallHistoryErrorUseCase extends MagicUseCase {
  protected async runLogic(index: number) {
    if (!index) {
      return
    }
    const rentalHistoryErrors = this.getState().user.rentalApplication.rentalHistoryErrors
    if (!rentalHistoryErrors) {
      return;
    }
    this.getState().user.rentalApplication.rentalHistoryErrors.splice(index, 1);
  }
}
