import { RentalHistoryStep } from '~/pages/rental-application/steps/RentalHistoryStep';

const RentalHistory = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <RentalHistoryStep />
    </div>
  );
};

export default RentalHistory;
